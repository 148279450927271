import React from 'react'

const Floating = () => {
  return (
    <div className='floating cursor-pointer fixed bottom-5 right-6 rounded-full w-[55px] h-[55px] bg-green-400 z-50 flex justify-center items-center'>
      <a className='loating__link text-white flex' href='https://wa.link/gcs8n5' target='_blank' rel='noreferrer'>
        <i className="fa fa-whatsapp fa-2x"></i>
      </a>
    </div>
  )
}

export default Floating