import React from 'react'
import { useForm, ValidationError } from '@formspree/react'

const Form = () => {
  
  const [ state, handleSubmit ] = useForm("xayzqzky")
  if (state.succeeded) {
    return window.location.href = 'https://465consultora.com/gracias.html'
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='form-group mb-4'>
          <input
            className='form-control shadow-xl shadow-slate-200 w-full px-4 py-3' placeholder='Nombre y Apellido *' type="text"  name='name'
          />
          <ValidationError 
            prefix="Nombre" 
            field="name"
            errors={state.errors}
          />
        </div>
        <div className='form-group mb-4'>
          <input
            className='form-control shadow-xl shadow-slate-200 w-full px-4 py-3' placeholder='Teléfono *' type="text" name='phone' 
          />
          <ValidationError 
            prefix="Teléfono" 
            field="phone"
            errors={state.errors}
          />
        </div>
        <div className='form-group mb-4'>
          <input
            className='form-control shadow-xl shadow-slate-200 w-full px-4 py-3' placeholder='E-mail *' type="email" name='email'
          />
           <ValidationError 
            prefix="Email" 
            field="email"
            errors={state.errors}
          />
        </div>
        <div className='form-group mb-4'>
          <textarea
            className='form-control shadow-xl shadow-slate-200 w-full px-4 py-3' placeholder='Mensaje' type="text" rows='5' name='message'
          >
            
          </textarea>
        </div>
        <div className='form-group mb-4'>
          <button
            className='form-control flex justify-center w-full px-4 py-3 bg-blue text-white uppercase tracking-widest pt-4'
            type="submit"
            disabled={state.submitting}
          > Enviar </button>
        </div>
      </form>
    </>
  )
}

export default Form