import React from 'react'
import Button from './Button'

const Service = ( { left, service } ) => {
  if( left ) {
    return (
      <article className='services__service py-24 lg:py-0 px-4 lg:flex lg:items-center lg:px-32 2xl:pr-64 2xl:pl-56' id={service.slug}>
        <div className='services__service--text lg:w-1/2 text-lg mb-12 lg:mb-0 2xl:pr-32'>
          <h1 className='f-black text-2xl lg:text-3xl 2xl:text-4xl mb-5 2xl:mb-8 tracking-widest uppercase'>{ service.name }</h1>
          <ul className='pl-4 2xl:mb-8'>
            {
              service.items.map(item => (
                <li className='list-disc mb-1 text-sm lg:text-lg 2xl:text-2xl' key={item}>{item}</li>
              ))
            }
          </ul>
          <p className='phrase f-bold-italic pl-5 ml-1 my-5 border-l-2 text-sm lg:text-lg border-solid leading-snug 2xl:mb-8' dangerouslySetInnerHTML={{ __html: `${service.phrase}` }}></p>
          <Button text={service.textButton} attrClass={'text-sm'} section={'contact'} />
        </div>
        <div className='services__service--media lg:w-1/2'>
          <img src={`../images/${service.image}`} className='max-w-full' alt={service.name} />
        </div>
      </article>
    )
  } else {
    return (
      <article className='services__service py-24 lg:py-0 px-4 lg:flex lg:items-center lg:px-32 2xl:pr-64 2xl:pl-56 flex-col-reverse lg:flex-row-reverse' id={service.slug}>
        <div className='services__service--text lg:w-1/2 text-lg mb-12 lg:mb-0 2xl:pr-32'>
          <h1 className='f-black text-2xl lg:text-3xl 2xl:text-4xl mb-5 2xl:mb-8 tracking-widest uppercase'>{ service.name }</h1>
          <ul className='pl-4 2xl:mb-8'>
            {
              service.items.map(item => (
                <li className='list-disc mb-1 text-sm lg:text-lg 2xl:text-2xl' key={item}>{item}</li>
              ))
            }
          </ul>
          <p className='phrase f-bold-italic pl-5 ml-1 my-5 border-l-2 text-sm lg:text-lg border-solid leading-snug 2xl:mb-8' dangerouslySetInnerHTML={{ __html: `${service.phrase}` }}></p>
          <Button text={service.textButton} attrClass={'text-sm'} section={'contact'} />
        </div>
        <div className='services__service--media lg:w-1/2'>
          <img src={`../images/${service.image}`} className='max-w-full' alt={service.name} />
        </div>
      </article>
    )
  }
}

export default Service