import React from 'react'

const Button = ( { text, attrClass, section } ) => {

  const handleScroll = (element) => {
    let topElem = document.getElementById(element)
    let top = topElem.offsetTop
    window.scrollTo({ top: top - 90, behavior: 'smooth' })
  }


  return (
    <>
      {
        section ? (
          <button className={`bg-blue-700 pt-2 pb-1 px-4 mb-5 lg:mb-0 lg:pt-3 lg:pb-2 lg:px-8 uppercase text-white tracking-widest text-sm lg:text-base ${attrClass}`} onClick={() => handleScroll(section)}> { text } </button>
        ) : (
          <button className={`bg-blue-700 pt-2 pb-1 px-4 mb-5 lg:mb-0 lg:pt-3 lg:pb-2 lg:px-8 uppercase text-white tracking-widest text-sm lg:text-base ${attrClass}`}> { text } </button>
        )
      }
    </>
  )
}

export default Button