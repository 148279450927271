import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick'
import Button from './Button';

const Carousel = ( { type } ) => {

  if(type === 'main'){
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000
    };
  
  
    return (
      <Slider {...settings}>
        <div className='main-banner relative h-screen lg:h-auto'>
          <img
            className='main-banner__image w-full object-cover block object-center h-screen lg:h-auto'
            src='../images/banner.jpg'
            alt='Banner Principal'
          />
          <div className='main-banner__content px-5 lg:px-0 2xl:px-16 absolute top-0 left-0 text-center mt-20 lg:mt-0 w-full h-full flex justify-center items-center text-white'>
            <div className='main-banner__content--child lg:w-3/5 2xl:mb-20'>
              <h1 className='text-2xl lg:text-6xl 2xl:text-7xl leading-none f-black mb-4 2xl:mb-8'>
                ¿Querés gestionar profesionalmente las finanzas de tu empresa?
              </h1>
              <p className='text-base lg:text-2xl 2xl:text-4xl leading-none mb-8 f-regular'>Te ayudamos a implementar las mejores prácticas del mercado.</p>
              <Button text={'Contactanos'} section={'contact'} />
            </div>
          </div>
        </div>
  
        <div className='main-banner relative h-screen lg:h-auto'>
          <img
            className='main-banner__image w-full object-cover block object-center h-screen lg:h-auto'
            src='../images/banner.jpg'
            alt='Banner Principal'
          />
          <div className='main-banner__content px-5 lg:px-0 2xl:px-16 absolute top-0 left-0 text-center mt-20 lg:mt-0 w-full h-full flex justify-center items-center text-white'>
            <div className='main-banner__content--child lg:w-3/5 2xl:mb-20'>
              <h1 className='text-2xl lg:text-6xl 2xl:text-7xl leading-none f-black mb-4 2xl:mb-8'>
                ¿Querés fidelizar el mejor talento en tu negocio?
              </h1>
              <p className='text-base lg:text-2xl 2xl:text-4xl leading-none mb-8 f-regular'>¡Te ayudamos a dar el salto profesional <br /> que necesitás!</p>
              <Button text={'Contactanos'} section={'contact'} />
            </div>
          </div>
        </div>

        <div className='main-banner relative h-screen lg:h-auto'>
          <img
            className='main-banner__image w-full object-cover block object-center h-screen lg:h-auto'
            src='../images/banner.jpg'
            alt='Banner Principal'
          />
          <div className='main-banner__content px-5 lg:px-0 2xl:px-16 absolute top-0 left-0 text-center mt-20 lg:mt-0 w-full h-full flex justify-center items-center text-white'>
            <div className='main-banner__content--child lg:w-3/5 2xl:mb-20'>
              <h1 className='text-2xl lg:text-6xl 2xl:text-7xl leading-none f-black mb-4 2xl:mb-8'>
                ¿Buscás inversores para tu negocio?
              </h1>
              <p className='text-base lg:text-2xl 2xl:text-4xl leading-none mb-8 f-regular'>Te asesoramos en el armado de la presentación institucional <br />para rondas de inversión.</p>
              <Button text={'Contactanos'} section={'contact'} />
            </div>
          </div>
        </div>

        <div className='main-banner relative h-screen lg:h-auto'>
          <img
            className='main-banner__image w-full object-cover block object-center h-screen lg:h-auto'
            src='../images/banner.jpg'
            alt='Banner Principal'
          />
          <div className='main-banner__content px-5 lg:px-0 2xl:px-16 absolute top-0 left-0 text-center mt-20 lg:mt-0 w-full h-full flex justify-center items-center text-white'>
            <div className='main-banner__content--child lg:w-3/5 2xl:mb-20'>
              <h1 className='text-2xl lg:text-6xl 2xl:text-7xl leading-none f-black mb-4 2xl:mb-8'>
                ¿Querés vender más?
              </h1>
              <p className='text-base lg:text-2xl 2xl:text-4xl leading-none mb-8 f-regular'>Con un Plan de Marketing tu empresa podrá <br />fortalecer el vínculo con sus clientes.</p>
              <Button text={'Contactanos'} section={'contact'} />
            </div>
          </div>
        </div>
      </Slider>
    )
  } else if(type === 'team'){
    const config = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 200,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000
    };
  
    return (
      <Slider {...config}>
        <div>
          <div className='lg:flex items-center'>
            <div className='lg:w-2/3 px-12 mb-10 lg:mb-0 lg:pl-20 lg:pr-52 2xl:pl-20 2xl:pr-96'>
              <h1 className='c-blue text-3xl lg:text-6xl f-black pt-2'>Abrí un nuevo negocio.</h1>
            </div>
            <div className='lg:w-1/3 relative'>
              <img
                className='absolute z-10 block left-4 top-7 lg:-left-14'
                src='../images/profile-horacio-omar-ojeda.png'
                alt='Horacio Ojeda'
              />
              <div className='forma bg-white lg:px-16 overflow-visible py-14 2xl:px-20 2xl:py-16'>
                <h1 className='c-blue text-xl f-black mb-14 lg:text-3xl pl-36 lg:mb-3 lg:pl-0'>
                  HORACIO<br />OJEDA
                </h1>
                <p className='border-l-2 border-black pl-5 text-lg ml-16 pr-4 lg:ml-0 lg:pr-0 2xl:text-2xl 2xl:pl-8'>Te asesoramos en el armado de la presentación institucional para tus inversores.</p>
                <span className='quotes f-black c-blue text-8xl block'>”</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className='lg:flex items-center'>
            <div className='lg:w-2/3 px-12 mb-10 lg:mb-0 lg:pl-20 lg:pr-52 2xl:pl-20 2xl:pr-96'>
              <h1 className='c-blue text-3xl lg:text-6xl f-black pt-2'>Sacá provecho del recurso estratégico de tu organización.</h1>
            </div>
            <div className='lg:w-1/3 relative'>
              <img
                className='absolute z-10 block left-4 top-7 lg:-left-14'
                src='../images/karen-kaenel.png'
                alt='Horacio Ojeda'
              />
              <div className='forma bg-white lg:px-16 overflow-visible py-14 2xl:px-20 2xl:py-16'>
                <h1 className='c-blue text-xl f-black mb-14 lg:text-3xl pl-36 lg:mb-3 lg:pl-0'>
                  KAREN<br />KAENEL
                </h1>
                <p className='border-l-2 border-black pl-5 text-lg ml-16 pr-4 lg:ml-0 lg:pr-0 2xl:text-2xl 2xl:pl-8'>Las empresas que profesionalizan su comunicación tienen mayor impacto.</p>
                <span className='quotes f-black c-blue text-8xl block'>”</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className='lg:flex items-center'>
            <div className='lg:w-2/3 px-12 mb-10 lg:mb-0 lg:pl-20 lg:pr-52 2xl:pl-20 2xl:pr-96'>
              <h1 className='c-blue text-3xl lg:text-6xl f-black pt-2'>Monitoreá tu empresa en tiempo real.</h1>
            </div>
            <div className='lg:w-1/3 relative'>
              <img
                className='absolute z-10 block left-4 top-7 lg:-left-14'
                src='../images/walter-gonzalez.png'
                alt='Walter Gonzalez'
              />
              <div className='forma bg-white lg:px-16 overflow-visible py-14 2xl:px-20 2xl:py-16'>
                <h1 className='c-blue text-xl f-black mb-14 lg:text-3xl pl-36 lg:mb-3 lg:pl-0'>
                  WALTER<br />GONZALEZ
                </h1>
                <p className='border-l-2 border-black pl-5 text-lg ml-16 pr-4 lg:ml-0 lg:pr-0 2xl:text-2xl 2xl:pl-8'>Diseñemos tableros de gestión con KPIs estratégicos que te ahorran tiempo y podes verlo desde cualquier lugar y dispositivo.</p>
                <span className='quotes f-black c-blue text-8xl block'>”</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className='lg:flex items-center'>
            <div className='lg:w-2/3 px-12 mb-10 lg:mb-0 lg:pl-20 lg:pr-52 2xl:pl-20 2xl:pr-96'>
              <h1 className='c-blue text-3xl lg:text-6xl f-black pt-2'>Ahorrá en impuestos.</h1>
            </div>
            <div className='lg:w-1/3 relative'>
              <img
                className='absolute z-10 block left-4 top-7 lg:-left-14'
                src='../images/ulises-rollhaise.png'
                alt='Ulises Rollahiser'
              />
              <div className='forma bg-white lg:px-16 overflow-visible py-14 2xl:px-20 2xl:py-16'>
                <h1 className='c-blue text-xl f-black mb-14 lg:text-3xl pl-36 lg:mb-3 lg:pl-0'>
                  ULISES<br />ROLLHAISER
                </h1>
                <p className='border-l-2 border-black pl-5 text-lg ml-16 pr-4 lg:ml-0 lg:pr-0 2xl:text-2xl 2xl:pl-8'>Con una planificación fiscal tu empresa puede reducir o postergar la carga tributaria dentro de la legalidad.</p>
                <span className='quotes f-black c-blue text-8xl block'>”</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className='lg:flex items-center'>
            <div className='lg:w-2/3 px-12 mb-10 lg:mb-0 lg:pl-20 lg:pr-52 2xl:pl-20 2xl:pr-96'>
              <h1 className='c-blue text-3xl lg:text-6xl f-black pt-2'> La última información del mercado.</h1>
            </div>
            <div className='lg:w-1/3 relative'>
              <img
                className='absolute z-10 block left-4 top-7 lg:-left-14'
                src='../images/andres-borenstein.png'
                alt='Andres Borenstein'
              />
              <div className='forma bg-white lg:px-16 overflow-visible py-14 2xl:px-20 2xl:py-16'>
                <h1 className='c-blue text-xl f-black mb-14 lg:text-3xl pl-36 lg:mb-3 lg:pl-0'>
                  ANDRES<br />BORENSTEIN
                </h1>
                <p className='border-l-2 border-black pl-5 text-lg ml-16 pr-4 lg:ml-0 lg:pr-0 2xl:text-2xl 2xl:pl-8'>Tener acceso a informes macroeconómicos te permitirá tomar mejores deciciones.</p>
                <span className='quotes f-black c-blue text-8xl block'>”</span>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    )
  }
}

export default Carousel