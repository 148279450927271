import React, { useState } from 'react'
import Carousel from './components/Carousel'
import Floating from './components/Floating'
import Navbar from './components/Navbar'
import Service from './components/Service'
import Services from './services.json'
import { Configuration, OpenAIApi } from 'openai'
import Form from './components/Form'

function App() {

  const [ result, setResult ] = useState('')
  // const [ empresa, setEmpresa ] = useState('Marketing')
  // const [ mision, setMision ] = useState('Acompañar el crecimiento de nuestros clientes')
  // const [ vision, setVision ] = useState('Ser referencia en la región del Chaco')
  // const [ valor, setValor ] = useState('Servicios personalizados')

  const handleScroll = (element) => {
    let topElem = document.getElementById(element)
    let top = topElem.offsetTop
    window.scrollTo({ top: top - 90, behavior: 'smooth' })
  }

  // const handleForm = () => {
  //   const configuration = new Configuration({
  //     apiKey: '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',
  //   })

  //   const openai = new OpenAIApi(configuration)

  //   openai.createCompletion({
  //     model: 'text-davinci-003',
  //     prompt: `Para una empresa de ${empresa} que no tiene nombre, con su misión: ${mision}, su visión: ${vision} y su valor: ${valor}. Quiero una sugerencia de nombre corto para mi empresa.`,
  //     temperature: 1,
  //     max_tokens: 256,
  //     top_p: 1,
  //     frequency_penalty: 0,
  //     presence_penalty: 0,
  //   })
  //   .then((response) => {
  //     setResult(response.data.choices[0].text)
  //   })
  // }

  return (
    <>
      <img
        className='circle animated fixed -bottom-4'
        src='../images/circle-animation.svg'
        alt='Circulo Animado'
      />
      <Navbar services={Services} />
      {/* <section className='openai mt-24 py-6'>
        <article className='min-w-[100px] max-w-4xl mx-auto'>
          <label>Industría de la empresa:</label>
          <input type="text" placeholder='Marketing' className='w-full p-2 rounded mb-4' onChange={(e) => setEmpresa(e.target.value)} />
          <label>Síntesis de misión:</label>
          <input type="text" placeholder='Acompañar el crecimiento de nuestros clientes' className='w-full p-2 rounded mb-4' onChange={(e) => setMision(e.target.value)} />
          <label>Síntesis de Visión:</label>
          <input type="text" placeholder='Ser referencia en la región del Chaco' className='w-full p-2 rounded mb-4' onChange={(e) => setVision(e.target.value)} />
          <label>Síntesis de Valores:</label>
          <input type="text" placeholder='Servicios personalizados' className='w-full p-2 rounded mb-4' onChange={(e) => setValor(e.target.value)} />
          <button className='rounded bg-blue-500 text-white px-6 py-2' onClick={handleForm}> Sugerir nombres </button>
        </article>
        <article className='min-w-[100px] max-w-4xl mx-auto mt-10'>
          <p>
            {
              result.length > 0 ? (
                <>
                  <p className=''>Nombre sugerido por nuestra inteligencia Artificial:</p>
                  <p className='font-bold'>{ result }</p>
                </>
              ) : (
                null
              )
            }
          </p>
        </article>
      </section> */}
      <section id='main' className='relative'>
        <Carousel type={'main'} />
        <span
          className='arrow-down absolute bottom-20 z-20 border px-1 pt-5 pb-3 border-white rounded-2xl cursor-pointer'
          onClick={() => handleScroll('services')}
        >
          <img
            src='images/icon-arrow-down.svg'
            className='h-[15px] mx-1 animate-bounce'
            alt='Arrow Down'
          />
        </span>
      </section>
      <section className='services relative' id='services'>
        {
          Services.length > 0 && Services.map((service, index) => (
            (index % 2 === 0) ? (
              <Service left={true} service={service} key={index} />
            ) : (
              <Service left={false} service={service} key={index} />
            )
          ))
        }
      </section>
      <section className='about lg:flex items-end px-4 lg:px-32 bg-gray lg:bg-contain bg-no-repeat bg-top' id='about'>
        <div className='pt-32 px-12 lg:pt-0 lg:w-1/2 lg:text-5xl lg:pl-20 lg:pr-28 2xl:pr-32'>
          <h1 className='text-white tracking-wide lg:mb-60 2xl:mb-96 text-2xl lg:text-5xl 2xl:text-6xl mb-10 text-center lg:text-left'>
            Somos una consultora de negocios para <br />
            <span className='f-black'>emprendedores ágiles</span>
          </h1>
        </div>
        <div className='lg:w-1/2'>
          <img
            src='images/somos-465-consultora-de-negocios.jpg'
            className='about__cover'
            alt='Imagen de fondo'
          />
          <div className='bg-white forma pt-8 pb-6 pl-10 pr-10 mx-4 z-10 relative text-base lg:py-20 lg:px-16 lg:text-lg 2xl:text-2xl 2xl:px-20 2xl:pt-24 2xl:pb-28'>
            <p className='mb-4'>Si estas buscando <span className='f-bold'>acompañamiento</span> para profesionalizar tu empresa, tenés una idea de negocio y querés buscar inversores, <span className='f-bold'>entonces podemos ayudarte</span>.</p>
            <p className='mb-0'>Nos especializamos en brindar <span className='f-bold'>atención personalizada</span>, porque entendemos que cada negocio es un mundo y no todos necesitan lo mismo.</p>
          </div>
        </div>
      </section>
      <section className='team pt-32 pb-32 px-4 lg:pl-32 lg:pr-40 2xl:pr-60 bg-gray relative' id='team'>
        <h1 className='lg:pl-20 text-xl 2xl:text-2xl tracking-widest c-blue opacity-30 mb-5'>NUESTRO <br />EQUIPO</h1>
        <div className='w-full max-w-full pt-8'>
          <Carousel type={'team'} />
        </div>
      </section>
      {/* <section className='partners bg-cover bg-no-repeat bg-top pt-32 pb-48'>
        <h1 className='text-white text-center mb-24 text-xl tracking-widest'>NUESTROS PARTNERS</h1>
        <div className='logos flex flex-col lg:flex-row px-4 lg:px-60 justify-around items-center'>
          <img className='block mb-14 lg:mb-0' src='images/logo-bitrix-24.svg' alt='Bitrix24' />
          <img className='block mb-14 lg:mb-0' src='images/logo-pda.svg' alt='PDA' />
          <img className='block mb-14 lg:mb-0' src='images/logo-clicdata.svg' alt='CLIC DATA' />
          <img className='block' src='images/logo-google.svg' alt='Google' />
        </div>
      </section> */}
      <section className='contact pt-36 pb-20 px-8 lg:px-32 lg:flex 2xl:px-40 border-t border-slate-300' id='contact'>
        <div className='lg:w-1/2 xl:pl-36'>
          <h1 className='c-blue f-black text-3xl 2xl:text-4xl mb-4 tracking-wider'>COORDINEMOS <br />UNA REUNIÓN</h1>
          <p className='text-lg 2xl:text-xl mb-2'>Estamos para brindarte <br />la información que necesitás.</p>
          <p className='text-lg 2xl:text-xl f-bold mb-16'>¡Solicitala ya!</p>
        </div>
        <div className='lg:w-1/2 xl:pr-20 2xl:pr-40'>
          <div id="form">
            <Form />
          </div>
        </div>
      </section>
      <footer className='border-t-2 border-solid mx-20 pt-8 pb-16 lg:mx-28 2xl:mx-40'>
        <p className='text-center'>© 2022 - 465 Consultora de Negocios - Todos los derechos reservados</p>
      </footer>
      <Floating />
    </>
  );
}

export default App;
